<template>
  <b-form>
    <b-row>
      <!-- old password -->
      <b-col md="6">
        <b-form-group
          label="Старый пароль"
          label-for="account-old-password"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-old-password"
              v-model="passwordValueOld"
              name="old-password"
              :type="passwordFieldTypeOld"
              placeholder="Старый пароль"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconOld"
                class="cursor-pointer"
                @click="togglePasswordOld"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--/ old password -->
    </b-row>
    <b-row>
      <!-- new password -->
      <b-col md="6">
        <b-form-group
          label-for="account-new-password"
          label="Новый пароль"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-new-password"
              v-model="newPasswordValue"
              :type="passwordFieldTypeNew"
              name="new-password"
              placeholder="Новый пароль"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconNew"
                class="cursor-pointer"
                @click="togglePasswordNew"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--/ new password -->

      <!-- retype password -->
      <b-col md="6">
        <b-form-group
          label-for="account-retype-new-password"
          label="Повторите новый пароль"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-retype-new-password"
              v-model="RetypePassword"
              :type="passwordFieldTypeRetype"
              name="retype-password"
              placeholder="Повторите новый пароль"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconRetype"
                class="cursor-pointer"
                @click="togglePasswordRetype"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--/ retype password -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1 mr-1"
          @click="save"
        >
          Изменить пароль
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-form>
</template>

<script>
import { mapState } from 'vuex'
import { coreApi } from '@/api'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/api/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    ...mapState('main', ['user']),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async save() {
      if (this.newPasswordValue !== this.RetypePassword) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Новый пароль не совпадает',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `Попробуйте еще раз`,
          },
        })
        return
      }
      
      try {
        const data = await coreApi.passwordUpdate({
          ...this.user,
          newPassword: this.newPasswordValue,
          oldPassword: this.passwordValueOld
        })

        useJwt.setToken(data.token)
        localStorage.setItem('userData', JSON.stringify(data.user))
        this.$ability.update(data.user.ability)

         this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Пароль успешно изменен',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Поздравляем`,
          },
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Неверный пароль',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `Попробуйте еще раз`,
          },
        })
      }
    }
  },
}
</script>
