<template>
  <div class="category-page-settings">
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in listCategories"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="10">
            <b-form-group
              label="Название раздела"
              label-for="title-category"
            >
              <b-form-input
                id="title-category"
                type="text"
                v-model="listCategories[index].value"
                placeholder="Введите название раздела для статей"
              />
            </b-form-group>
          </b-col>


          <!-- id -->
          <!-- <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Id"
              label-for="Id"
            >
              <b-form-input
                id="id"
                :value="listCategories[index].id"
                plaintext
              />
            </b-form-group>
          </b-col> -->

          <!-- Remove Button -->
          <b-col
            md="2"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <!-- <span>Удалить</span> -->
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Добавить раздел</span>
    </b-button>
    <b-button
      class="ml-1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="save"
    >
      <span>Сохранить</span>
    </b-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { coreApi } from '@/api'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      listCategories: []
    }
  },
  mounted() {
    this.listCategories = this.categories
  },
  computed: {
    ...mapState('main', ['user', 'categories']),
  },
  methods: {
    ...mapMutations('main', ['setCategories']),
    repeateAgain() {
      const id = this.listCategories.length ?
        this.listCategories[this.listCategories.length - 1].id + 1 :
        1;

      this.listCategories.push({
        id,
        value: ''
      })
    },
    removeItem(index) {
      this.listCategories.splice(index, 1)
    },
    save() {
      const categories = this.listCategories.filter((item) => item.value)
      this.setCategories(categories)
      const res = coreApi.updateCategory({
        categories,
        userId: this.user.id
      })

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Категории успешно сохранены',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: `Поздравляем`,
        },
      })

      this.$emit('close');
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>