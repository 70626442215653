<template>
  <b-row>
    <h6 class="section-label mx-1 mb-2">
        Уведомлять сотрудников
    </h6>

    <!-- application switch -->
    <h6 class="section-label mx-1 mt-2">
        Скоро появится
    </h6>

    <b-col
        cols="12"
        class="mb-2"
    >
        <b-form-checkbox
        id="accountSwitch1"
        :checked="notify.email"
        name="check-button"
        disabled
        switch
        inline
        >
        <span>Email(если указан)</span>
        </b-form-checkbox>
    </b-col>
    <b-col
        cols="12"
        class="mb-1"
    >
        <b-form-checkbox
        id="accountSwitch3"
        :checked="notify.telegram"
        name="check-button"
        disabled
        switch
        inline
        >
        <span>Telegram(если установлен)</span>
        </b-form-checkbox>
    </b-col>

    <b-col
        cols="12"
        class="mt-1 mb-2"
    >
        <b-form-checkbox
        id="accountSwitch2"
        :checked="notify.phone"
        name="check-button"
        disabled
        switch
        inline
        >
        <span>Телефон(если указан)</span>
        </b-form-checkbox>
    </b-col>
    <b-col
        cols="12"
        class="mb-2"
    >
        <b-form-checkbox
        id="accountSwitch4"
        disabled
        name="check-button"
        switch
        inline
        >
        <span>Уведомления по СМС</span>
        </b-form-checkbox>
    </b-col>
    <b-col
        cols="12"
        class="mb-2"
    >
        <b-form-checkbox
        id="accountSwitch5"
        disabled
        name="check-button"
        switch
        inline
        >
        <span>Уведомления Whatsapp</span>
        </b-form-checkbox>
    </b-col>
    <!--/ application switch -->

    <!-- buttons -->
    <b-col cols="12">
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1 mt-1"
        >
        Сохранить изменения
        </b-button>
    </b-col>
    <!--/ buttons -->
  </b-row>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notify: {
        phone: false,
        email: false,
        telegram: false,
      }
    }
  },
}
</script>
