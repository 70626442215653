<template>
  <div class="bitrix">
    <div v-if="isb24key" class="key">
      <div class="title mb-2">Для интеграции с Битрикс 24 введите входящий вебхук</div>

      <b-input-group>
        <b-form-input placeholder="Ваш вебхук" v-model="b24Key" />
        <b-input-group-append>
          <b-button variant="primary" @click="saveApiKey">
            Подключить
          </b-button>
        </b-input-group-append>
      </b-input-group>
      
      <a href="https://stockwelldev.notion.site/24-a7020b0e24f746fdb87ebd539a922b98" target="_blank">Как создать вебхук?</a>
    </div>
    <div v-else class="content-bitrix-integration">
      <div class="section">
        <header>
          <div class="title">Синхронизировать сотрудников Битрикс24</div>
          <b-button
            v-if="!isFullUsers"
            variant="primary"
            @click="getUsers"
          >
            Синхронизировать
          </b-button>
          <div v-else class="icon" @click="isFullUsers = !isFullUsers">
            <feather-icon
              icon="ChevronUpIcon"
              size="16"
            />
          </div>
        </header>

        <div v-if="isFullUsers" class="content-users">
          <Preloader v-if="isLoadUsers" />
          <div v-else class="tabel">

            <table>
              <thead>
                <tr>
                  <th>
                    <b-form-checkbox v-model="checkAll"></b-form-checkbox>
                  </th>
                  <th>Сотрудник</th>
                  <th>Email</th>
                  <!-- <th>Отправить пароль для входа в Битриксе</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) of users"
                  :key="index"
                >
                  <td>
                    <b-form-checkbox v-model="users[index].isAdd"></b-form-checkbox>
                  </td>
                  <td>
                    <div class="user">
                      <img :src="item.PERSONAL_PHOTO || 'https://w7.pngwing.com/pngs/419/473/png-transparent-computer-icons-user-profile-login-user-heroes-sphere-black-thumbnail.png'">
                      <div class="info">
                        <div class="name">{{ item.LAST_NAME }} {{ item.NAME }}</div>
                        <div class="position">{{ item.WORK_POSITION }}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="email">{{ item.EMAIL }}</div>
                  </td>
                  <!-- <td class="center-text">
                    <b-form-checkbox v-model="users[index].isSendPassword"></b-form-checkbox>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <b-button
          v-if="isFullUsers"
          class="mt-2"
          variant="primary"
          @click="addUsers"
        >
          Добавить сотрудников в Eddy24
        </b-button>
      </div>

      <br />

      <div class="button soft" @click="isb24key = true">Изменить API ключ</div>
    </div>
  </div>
</template>

<script>
import { coreApi } from '@/api'
import { mapState } from 'vuex'
import {BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox} from 'bootstrap-vue'
import Preloader from '@/components/base/Preloader.vue'

export default {
  name: 'Bitrix',
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    Preloader
  },
  data() {
    return {
      checkAll: true,

      users: [],
      isLoadUsers: false,
      isFullUsers: false,
      isb24key: true,
      b24Key: ''
    }
  },
  watch: {
    checkAll() {
      this.users = this.users.map((item) => {
        item.isAdd = this.checkAll
        return item
      })
    }
  },
  computed: {
    ...mapState('main', ['user']),
  },
  methods: {
    async saveApiKey() {
      await coreApi.userUpdate({
        ...this.user,
        b24Key: this.b24Key
      })
      this.isb24key = false
    },
    async addUsers() {
      const users = this.users.filter(item => item.isAdd)
      await coreApi.addUsersFromB24({
        users,
        user: this.user
      })
    },
    async getUsers() {
      this.isLoadUsers = true
      this.isFullUsers = !this.isFullUsers
      const result = await coreApi.getUsersFromB24(this.user)
      let users = result.b24;

      this.users = users.filter((user) => {
        const userInEddy = result.eddy.find((userEddy) => {
          return user.EMAIL === userEddy.email
        })
        return userInEddy === undefined
      })
      .filter((item) => item.ACTIVE)
      .map((item) => {
        return {
          ...item,
          isAdd: true,
          isSendPassword: true
        }
      });

      this.isLoadUsers = false;
    }
  },
  async mounted() {
    this.isb24key = !this.user.b24Key;
  }
}
</script>

<style lang="scss" scoped>
.bitrix {
  width: 100%;

  .key {
    width: 100%;
  }

  .content-bitrix-integration {
    width: 100%;

    .section {
      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        background: #f0f0f082;
        border-radius: 10px;

        .title {
          font-weight: 500;
        }
      }
    }

    .button {
      width: max-content;
      position: relative;
      padding: 10px 20px;
      background: rgba(0, 136, 204, 0.1);
      border-radius: 0.358rem;
      color: #0088CC;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .content-users {
    table {
      border-collapse: collapse;
      border-radius: 5px;
      width: 100%;

      .user {
        width: 100%;
        display: flex;
        align-items: center;

        img {
          border-radius: 50px;
          margin-right: 10px;
          width: 35px;
          height: 35px;
        }

        .info {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .name {
            font-size: 15px;
          }

          .position {
            font-size: 13px;
            color: #adadad;
          }
        }
      }

      .email {
        font-weight: 500;
      }

      th, td {
        padding: 8px;
      }

      tr {
        &:nth-child(even) {
          background: #efefef; 
        }

        &:hover {
          background: #ededed;
        }
      }

      thead {
        tr {
          th {
            font-weight: 400;
          }

          &:hover {
            background: none;
          }
        }
      }

      .center-text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
