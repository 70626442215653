<template>
  <div class="integrations">
    <div class="tabs">
      <div
        v-for="(item, index) of tabs"
        :key="index"
        :class="{ active: item.component === tab }"
        class="tab"
      >
        {{ item.title }}
      </div>
    </div>

    <div class="content-integrations">
      <component :is="tab" />
    </div>
  </div>
</template>

<script>
import Bitrix from '@/views/setting/integrations/Bitrix' 

export default {
  name: 'Integrations',
  components: { Bitrix },
  data() {
    return {
      tab: 'Bitrix',
      tabs: [
        {
          component: 'Bitrix',
          title: 'Битрикс24',
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.integrations {
  width: 100%;

  .tabs {
    width: 100%;
    
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      font-weight: 500;
      width: max-content;
      height: 40px;
      padding: 0 20px;
      border-radius: 5px;

      &.active {
        background: #0088cc;
        color: #fff;
      }
    }
  }

  .content-integrations {
    width: 100%;
    margin-top: 20px;
  }
}
</style>