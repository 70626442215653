<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Оснавная информация</span>
      </template>

      <General />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Пароль</span>
      </template>

      <Password />
    </b-tab>
    <!--/ change password tab -->

    <!-- employees -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Сотрудники</span>
      </template>

      <Employees />
    </b-tab> -->

    <!-- integration -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="GitPullRequestIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Интеграции</span>
      </template>

      <Integrations />
    </b-tab>


    <!-- categoryq -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlignLeftIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Разделы для статей</span>
      </template>

      <Category />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Уведомления</span>
      </template>

      <Notification/>
    </b-tab>
  </b-tabs>
</template>

<script>
import Category from '@/views/setting/Category.vue'
import Notification from '@/views/setting/account/Notification.vue'
import Password from '@/views/setting/account/Password.vue'
import Integrations from '@/views/setting/account/Integrations.vue'
import General from '@/views/setting/account/General.vue'
// import Employees from '@/views/Employees.vue'
import { BTabs, BTab } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,

    Category,
    Notification,
    // Employees,
    Password,
    General,
    Integrations
  },
  data() {
    return {
      options: {},
    }
  }
}
</script>

<style lang="scss">
.tab-content {
  .tab-pane {
    margin-bottom: 2rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(34, 41, 47, 0.125);
    border-radius: 0.428rem;
    display: block;
    padding: 15px 15px 30px;

    // .employees {
    //   box-shadow: none;
    //   padding: 0;

    //   .header .left {
    //     display: none;
    //   }
    // }
  }
}
</style>
