<template>
  <b-form class="mt-2">
    <b-row>
      <b-col sm="6">
        <b-form-group
          label="Username"
          label-for="account-login"
        >
          <b-form-input
            v-model="userData.username"
            placeholder="login"
            name="login"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
          label="Имя"
          label-for="account-name"
        >
          <b-form-input
            v-model="userData.fullName"
            name="name"
            placeholder="Имя"
          />
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
          label="E-mail"
          label-for="account-e-mail"
        >
          <b-form-input
            v-model="userData.email"
            name="email"
            placeholder="Email"
          />

        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
          label="Название компании"
          label-for="account-company"
        >
          <b-form-input
            v-model="userData.company"
            name="company"
            placeholder="Название компании"
          />
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
          label="Телефон"
          label-for="account-company"
        >
          <b-form-input
            v-model="userData.phone"
            name="company"
            placeholder="Телефон"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="save"
        >
          Сохранить изменения
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { coreApi } from '@/api'
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: {},
      profileFile: null,
    }
  },
  computed: {
    ...mapState('main', ['user']),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.userData = this.user
    },
    async save() {
      const res = await coreApi.userUpdate(this.userData)
      console.log('res', res);

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Данные успешно сохранены',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: `Поздравляем`,
        },
      })
    }
  }
}
</script>
